import React, {useState} from 'react';
import cx from 'classnames';

import classes from './Textarea.module.scss';

const Textarea = ({
  name,
  value,
  state = 'default', // 'default' | 'success' | 'error'
  placeholder,
  disabled,
  rows = 5,
  message,
  onChange,
  onBlur
}) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <>
    <textarea
      {...{ name, value, placeholder, disabled, rows }}
      className={cx(
        classes.textarea,
        state && classes[`textarea--state-${state}`],
        isFocused && classes['textarea--focused']
      )}
      onChange={e => onChange && onChange(e.target.value)}
      onFocus={() => setFocused(true)}
      onBlur={e => {
        setFocused(false);
        onBlur && onBlur(e);
      }}
    >
        {value}
      </textarea>
      {!!message && <div className={classes.message}>{message}</div>}
    </>
  )
};

export default Textarea;
