import React, { useRef, useState } from 'react';
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { IoImagesOutline } from 'react-icons/io5';

import classes from './Uploader.module.scss';

// state = 'default' | 'success' | 'error'

const Photo = ({ onSelect, multiple = true, state = 'default', message }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const changeInput = (e) => {
    const files = e.target.files;

    if (files) {
      setFile(files[0]);
      onSelect(files);
    }
  }

  return (
    <>
      <div
        className={cx(
          classes.wrapper,
          state && classes[`wrapper--state-${state}`],
          file?.name && classes['wrapper--uploaded']
        )}
        onClick={() => inputRef.current.click()}
      >
        <input
          onChange={changeInput}
          name='photo'
          accept='image/*'
          multiple={multiple}
          ref={inputRef}
          type="file"
        />
        <IoImagesOutline size={24} />
        <div className={classes.name}>{file?.name || t(`choose_image`)}</div>
        <AiOutlineDelete
          onClick={e => {
            e.stopPropagation();
            setFile(null);
            onSelect(null);
          }}
          size={24}
          color='#F61E2E'
        />
      </div>

      {!!message && <div className={classes.message}>{message}</div>}
    </>
  )
}

export default Photo;