import { http } from 'services';

export const List = ({ params }) => http.request.get('homeslider');

export const Single = ({ id }) => http.request.get(`homeslider/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('title', JSON.stringify(title));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('homeslider', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('id', id);
  formData.append('title', JSON.stringify(title));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('homeslider', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('homeslider', {
  data: {
    id
  }
});