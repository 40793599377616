import { http } from 'services';

export const List = ({ params }) => http.request.get('game', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`game/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('region_id', values.regionId);
  formData.append('type', values.type);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('game', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('region_id', values.regionId);
  formData.append('type', values.type);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('game', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('game', {
  data: {
    id
  }
});