import { http } from 'services';

export const List = ({ params }) => http.request.get('text', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`text/single/${id}`)

export const Create = ({ values }) => {
  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const data = {
    name,
    tag: values.tag,
    status: !!values.status
  }

  return http.request.post('text', data);
};

export const Update = ({ id, values }) => {
  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const data = {
    id,
    name,
    tag: values.tag,
    status: !!values.status
  }

  return http.request.put('text', data);
};

export const Delete = ({ id }) => http.request.delete('text', {
  data: {
    id
  }
});