import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from './Base/Input';

const Password = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('password')} />
      <Input
        type='password'
        name='password'
        placeholder={t('password_enter')}
      />
    </>
  )
};

export default Password;
