import get from 'lodash/get';
import { useQuery } from 'react-query';

import config from "config";

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useList = ({ params }) => {
  const initialData = { items: [], meta: Mappers.Meta() };

  const paramsWithDefaults = {
    ...params,
    limit: config.list.limit
  }

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'list', paramsWithDefaults],
    async () => {
      const { data } = await Api.List({ params: paramsWithDefaults });

      const items = (get(data, 'data') || []).map(item => Mappers.Participant(item));

      const meta = Mappers.Meta(data);

      return { items, meta };
    },
    { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useList;
