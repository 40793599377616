import { http } from 'services';

export const ReferensEssential = () => http.request.get('referens/essential');

export const List = ({ params }) => http.request.get('essential', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`essential/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  if (!!values.photo) {
    formData.append('media', values.photo[0]);
  }

  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('essential', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  if (!!values.photo) {
    formData.append('media', values.photo[0]);
  }

  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('essential', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('essential', {
  data: {
    id
  }
});