import get from 'lodash/get';

export const Game = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  regionId: get(item, 'region_id') || '',
  type: get(item, 'type') || '',
  status: get(item, 'status') || false,
  video: get(item, 'video') || '',
  photo: get(item, 'photo') || []
});

export const Meta = (item) => ({
  totalPages: get(item, 'total_pages') || 0,
  totalItems: get(item, 'total_count') || 0,
  current: get(item, 'page') ? get(item, 'page') + 1 : 1,
  perPage: get(item, 'size') || 1
});