import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Date = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('work_time')} required />
      <Input
        name='workTime'
        placeholder={t('work_time_enter')}
      />
    </>
  )
};

export default Date;