import React from 'react';
import { useTranslation } from "react-i18next";

import * as Forms from 'modules/auth/forms';
import * as Fields from 'containers/Fields'

import Button from "components/Button";
import Spacer from "components/Spacer";

const Auth = () => {
  const { t } = useTranslation();

  return (
    <Forms.Login>
      {({ isValid }) => (
        <>
          <Fields.Username />

          <Spacer size={20} />

          <Fields.Password />

          <Spacer size={20} />

          <Button
            variant='primary'
            title={t('login')}
            type='submit'
            disabled={!isValid}
            block
          />
        </>
      )}
    </Forms.Login>
  )
}

export default Auth;