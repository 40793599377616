import React from "react";
import { Routes, Route, Navigate } from "react-router";

import useAuth from "modules/auth/hooks";

import * as Layout from "layouts";

import Auth from 'pages/Auth';

import * as Game from "pages/Game";
import * as Bank from "pages/Bank";
import * as Food from "pages/Food";
import * as Park from "pages/Park";
import * as About from "pages/About";
import * as Train from "pages/Train";
import * as Hotel from "pages/Hotel";
import * as Album from "pages/Album";
import * as Region from "pages/Region";
import * as Shrine from "pages/Shrine";
import * as Museum from "pages/Museum";
import * as Market from "pages/Market";
import * as Clinic from "pages/Clinic";
import * as Mosque from "pages/Mosque";
import * as Leader from "pages/Leader";
import * as Airport from "pages/Airport";
import * as Theater from "pages/Theater";
import * as Essential from "pages/Essential";
import * as HomeSlider from "pages/HomeSlider";
import * as Restaurant from "pages/Restaurant";
import * as Participant from "pages/Participant";
import * as Translation from "pages/Translation";
import * as SubEssential from "pages/SubEssential";

import Spinner from "components/Spinner";

const App = () => {
  const { isAuthenticated, isFetched } = useAuth();

  if (!isFetched) {
    return <Spinner />;
  }

  if (!isAuthenticated) {
    return (
      <Layout.Auth>
        <Routes>
          <Route path="*" element={<Auth />} />
        </Routes>
      </Layout.Auth>
    )
  }

  return (
    <Layout.Main>
      <Routes>
        <Route path="/region" element={<Region.List />} />
        <Route path="/region/create" element={<Region.Create />} />
        <Route path="/region/update/:id" element={<Region.Update />} />

        <Route path="/shrine" element={<Shrine.List />} />
        <Route path="/shrine/create" element={<Shrine.Create />} />
        <Route path="/shrine/update/:id" element={<Shrine.Update />} />

        <Route path="/folk-game" element={<Game.List />} />
        <Route path="/folk-game/create" element={<Game.Create />} />
        <Route path="/folk-game/update/:id" element={<Game.Update />} />

        <Route path="/national-food" element={<Food.List />} />
        <Route path="/national-food/create" element={<Food.Create />} />
        <Route path="/national-food/update/:id" element={<Food.Update />} />

        <Route path="/leisure-park" element={<Park.List />} />
        <Route path="/leisure-park/create" element={<Park.Create />} />
        <Route path="/leisure-park/update/:id" element={<Park.Update />} />

        <Route path="/airport" element={<Airport.List />} />
        <Route path="/airport/create" element={<Airport.Create />} />
        <Route path="/airport/update/:id" element={<Airport.Update />} />

        <Route path="/train" element={<Train.List />} />
        <Route path="/train/create" element={<Train.Create />} />
        <Route path="/train/update/:id" element={<Train.Update />} />

        <Route path="/theater-cinema" element={<Theater.List />} />
        <Route path="/theater-cinema/create" element={<Theater.Create />} />
        <Route path="/theater-cinema/update/:id" element={<Theater.Update />} />

        <Route path="/bank" element={<Bank.List />} />
        <Route path="/bank/create" element={<Bank.Create />} />
        <Route path="/bank/update/:id" element={<Bank.Update />} />

        <Route path="/market" element={<Market.List />} />
        <Route path="/market/create" element={<Market.Create />} />
        <Route path="/market/update/:id" element={<Market.Update />} />

        <Route path="/museum" element={<Museum.List />} />
        <Route path="/museum/create" element={<Museum.Create />} />
        <Route path="/museum/update/:id" element={<Museum.Update />} />

        <Route path="/clinic" element={<Clinic.List />} />
        <Route path="/clinic/create" element={<Clinic.Create />} />
        <Route path="/clinic/update/:id" element={<Clinic.Update />} />

        <Route path="/hotel" element={<Hotel.List />} />
        <Route path="/hotel/create" element={<Hotel.Create />} />
        <Route path="/hotel/update/:id" element={<Hotel.Update />} />

        <Route path="/restaurant" element={<Restaurant.List />} />
        <Route path="/restaurant/create" element={<Restaurant.Create />} />
        <Route path="/restaurant/update/:id" element={<Restaurant.Update />} />

        <Route path="/mosque" element={<Mosque.List />} />
        <Route path="/mosque/create" element={<Mosque.Create />} />
        <Route path="/mosque/update/:id" element={<Mosque.Update />} />

        <Route path="/essential" element={<Essential.List />} />
        <Route path="/essential/create" element={<Essential.Create />} />
        <Route path="/essential/update/:id" element={<Essential.Update />} />

        <Route path="/sub-essential" element={<SubEssential.List />} />
        <Route path="/sub-essential/create" element={<SubEssential.Create />} />
        <Route path="/sub-essential/update/:id" element={<SubEssential.Update />} />

        <Route path="/home-slider" element={<HomeSlider.List />} />
        <Route path="/home-slider/create" element={<HomeSlider.Create />} />
        <Route path="/home-slider/update/:id" element={<HomeSlider.Update />} />

        <Route path="/album" element={<Album.List />} />
        <Route path="/album/create" element={<Album.Create />} />
        <Route path="/album/update/:id" element={<Album.Update />} />

        <Route path="/leader" element={<Leader.List />} />
        <Route path="/leader/create" element={<Leader.Create />} />
        <Route path="/leader/update/:id" element={<Leader.Update />} />

        <Route path="/participant" element={<Participant.List />} />
        <Route path="/participant/create" element={<Participant.Create />} />
        <Route path="/participant/update/:id" element={<Participant.Update />} />

        <Route path="/about/update" element={<About.Update />} />

        <Route path="/translation" element={<Translation.List />} />
        <Route path="/translation/create" element={<Translation.Create />} />
        <Route path="/translation/update/:id" element={<Translation.Update />} />

        <Route path='/' element={<Navigate to='/region' />} />
      </Routes>
    </Layout.Main>
  );
}

export default App;
