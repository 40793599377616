import { http } from 'services';

export const List = ({ params }) => http.request.get('photoAlbom', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`photoAlbom/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('photoAlbom', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  if (!!values.photo) {
    formData.append('media', values.photo[0]);
  }

  formData.append('id', id);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('photoAlbom', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('photoAlbom', {
  data: {
    id
  }
});