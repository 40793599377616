import { http } from 'services';

export const Single = () => http.request.get('about');

export const Update = ({ values }) => http.request.put('about', {
  about: {
    oz: values.about.oz,
    uz: values.about.uz,
    ru: values.about.ru,
    en: values.about.en
  },
  target: {
    oz: values.target.oz,
    uz: values.target.uz,
    ru: values.target.ru,
    en: values.target.en
  },
  status: values.status
})