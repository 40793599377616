import React from 'react';
import { useTranslation } from "react-i18next";

import * as Hooks from "modules/region/hooks";

import Label from "components/Label";
import Select from "./Base/Select";

const Region = () => {
  const { t, i18n } = useTranslation();

  const { items, isFetched } = Hooks.useRegions();

  return (
    <>
      <Label title={t('region_id')} required />
      <Select
        name='regionId'
        disabled={!isFetched}
        options={[
          { title: t('select_region'), value: '' },
          ...items.map(item => ({ title: item.name[i18n.language], value: item.id }))
        ]}
      />
    </>
  )
};

export default Region;