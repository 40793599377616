import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { SearchIcon } from "assets/icons";

import classes from './Search.module.scss';

const Search = () => {
  const { t } = useTranslation();
  const [searchParams , setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');

  const page = searchParams.get('page');

  return (
    <div className={classes.wrapper}>
      <input
        className={classes.input}
        onChange={e => setSearch(e.target.value)}
        type="text"
        defaultValue={search}
        placeholder={`${t('search')}...`}
      />
      <div onClick={() => {
        if (page) {
          setSearchParams({ search, page });
        } else {
          setSearchParams({ search });
        }
      }} className={classes.icon}>
        <SearchIcon />
      </div>
    </div>
  )
};

export default Search;