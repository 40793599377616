import React from 'react';

const DeleteIcon = ({ onClick }) => (
  <svg onClick={onClick} style={{ cursor: 'pointer' }} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19.8333 5.83301V4.66634C19.8333 3.37768 18.7887 2.33301 17.5 2.33301H10.5C9.21134 2.33301 8.16667 3.37768 8.16667 4.66634V5.83301H4.66667C4.02234 5.83301 3.5 6.35534 3.5 6.99967C3.5 7.64401 4.02234 8.16634 4.66667 8.16634H5.83333V20.9997C5.83333 22.9327 7.40034 24.4997 9.33333 24.4997H18.6667C20.5997 24.4997 22.1667 22.9327 22.1667 20.9997V8.16634H23.3333C23.9777 8.16634 24.5 7.64401 24.5 6.99967C24.5 6.35534 23.9777 5.83301 23.3333 5.83301H19.8333ZM17.5 4.66634H10.5V5.83301H17.5V4.66634ZM19.8333 8.16634H8.16667V20.9997C8.16667 21.644 8.689 22.1663 9.33333 22.1663H18.6667C19.311 22.1663 19.8333 21.644 19.8333 20.9997V8.16634Z" fill="#EE1D00"/>
    <path d="M10.5 10.4997H12.8333V19.833H10.5V10.4997Z" fill="#EE1D00"/>
    <path d="M15.1667 10.4997H17.5V19.833H15.1667V10.4997Z" fill="#EE1D00"/>
  </svg>

);

export default DeleteIcon;