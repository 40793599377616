import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import PaginationBase from 'components/Pagination';

const Pagination = ({ total }) => {
  const [searchParams , setSearchParams] = useSearchParams();

  const search  = searchParams.get('search') || '';
  const current = searchParams.get('page') || 1;

  useEffect(() => {
    setSearchParams({ page: current });
  }, []); // eslint-disable-line

  return (
    <PaginationBase
      {...{ total, current }}
      onChange={page => {
        if (search) {
          setSearchParams({ search, page });
        } else {
          setSearchParams({ page });
        }
      }}
    />
  );
};

export default Pagination;