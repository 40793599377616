import React from 'react';
import { useField } from 'formik';

import InputBase from 'components/Input';

const Input = ({ name, ...props }) => {
  const [field, meta, helper] = useField({ name });
  const hasError = !!(meta.error && meta.touched);

  return (
    <InputBase
      {...field}
      {...props}
      value={field.value || ''}
      message={hasError ? meta.error : ''}
      state={hasError ? 'error' : 'default'}
      onChange={value => helper.setValue(value)}
    />
  );
};

export default Input;
