import { http } from 'services';

export const ReferensRegion = () => http.request.get('referens/region');

export const List = ({ params }) => http.request.get(`region/${params.search}`);

export const Single = ({ id }) => http.request.get(`region/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const shortInfo = {
    oz: values.shortInfo.oz,
    uz: values.shortInfo.uz,
    ru: values.shortInfo.ru,
    en: values.shortInfo.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('name', JSON.stringify(name));
  formData.append('short_info', JSON.stringify(shortInfo));
  formData.append('shrine_count', values.shrineCount);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('region', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const shortInfo = {
    oz: values.shortInfo.oz,
    uz: values.shortInfo.uz,
    ru: values.shortInfo.ru,
    en: values.shortInfo.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('short_info', JSON.stringify(shortInfo));
  formData.append('shrine_count', values.shrineCount);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('region', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('region', {
  data: {
    id
  }
});