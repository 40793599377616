import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import * as Uploader from "./Base/Uploader";

const Photo = ({ multiple }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('photo')} required />
      <Uploader.Photo multiple={multiple} />
    </>
  )
}

export default Photo;