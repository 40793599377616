import React from 'react';
import { useDispatch } from "react-redux";
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Form, Formik } from 'formik';
import { toast } from "react-toastify";

import { yup } from 'services';

import * as Api from '../api';
import * as Actions from '../actions';
import * as Mappers from '../mappers';

const Login = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutation = useMutation(
    async values => {
      dispatch(Actions.Login.request());

      const result = await Api.Login({ values });

      return Mappers.Token(result.data);
    },
    {
      onSuccess: ({ token }) => {
        toast.success('successfully_login');
        navigate('/');
        dispatch(Actions.Login.success(token));
      },
      onError: () => {
        dispatch(Actions.Login.error());
      }
    }
  );

  const validationSchema = yup.object().shape({
    username: yup.string().required(t('required')),
    password: yup.string().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onError: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        username: '',
        password: ''
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Login;
