import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('oz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['name', 'shortInfo']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Text
                title='name'
                name={`name[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Info
                title='short_info'
                name={`shortInfo[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Number
                title='shrine_count'
                name='shrineCount'
              />
            </Col>
            <Col sm={12}>
              <Fields.Text
                title='video'
                name='video'
              />
            </Col>
            <Col sm={12}>
              <Fields.Photo />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
}

export default Form;