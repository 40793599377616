import get from 'lodash/get';

export const Theater = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  address: {
    oz: get(item, 'address.oz') || '',
    uz: get(item, 'address.uz') || '',
    ru: get(item, 'address.ru') || '',
    en: get(item, 'address.en') || ''
  },
  location: {
    x: get(item, 'location.x') || '',
    y: get(item, 'location.y') || ''
  },
  regionId: get(item, 'region_id') || '',
  shrineId: get(item, 'shrine_id') || '',
  site: get(item, 'link.site') || '',
  youtube: get(item, 'link.youtube') || '',
  telegram: get(item, 'link.telegram') || '',
  instagram: get(item, 'link.instagram') || '',
  facebook: get(item, 'link.facebook') || '',
  phone: get(item, 'phone') || '+998',
  workTime: get(item, 'work_time') || '',
  status: get(item, 'status') || false,
  photo: get(item, 'photo') || []
});

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});