import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineAudiotrack } from 'react-icons/md';

import classes from './Uploader.module.scss';

const Audio = ({ lang, onSelect, state = 'default', message }) => {
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);

  const changeInput = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
      onSelect(file);
    }
  }

  return (
    <>
      <div
        className={cx(
          classes.wrapper,
          state && classes[`wrapper--state-${state}`],
          file?.name && classes['wrapper--uploaded']
        )}
        onClick={() => inputRef.current.click()}
      >
        <input
          onChange={changeInput}
          name={`audio.${lang}`}
          accept='audio/*'
          ref={inputRef}
          type="file"
        />
        <MdOutlineAudiotrack size={24} />
        <div className={classes.name}>{file?.name || t('choose_audio')}</div>
        <AiOutlineDelete
          onClick={e => {
            e.stopPropagation();
            setFile(null);
            onSelect(null);
          }}
          size={24}
          color='#F61E2E'
        />
      </div>

      {!!message && <div className={classes.message}>{message}</div>}
    </>
  )
}

export default Audio;