import React from 'react';
import { useField } from 'formik';

import TextareaBase from 'components/Textarea';

const Textarea = ({ name, ...props }) => {
  const [field, meta, helper] = useField({ name });
  const hasError = !!(meta.error && meta.touched);

  return (
    <TextareaBase
      {...field}
      {...props}
      value={field.value || ''}
      message={hasError ? meta.error : ''}
      state={hasError ? 'error' : 'default'}
      onChange={value => helper.setValue(value)}
    />
  );
};

export default Textarea;
