import React from 'react';
import { useTranslation } from "react-i18next";
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.About(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    about: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    target: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    })
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        about: {
          oz: values.about.oz,
          uz: values.about.uz,
          ru: values.about.ru,
          en: values.about.en,
        },
        target: {
          oz: values.target.oz,
          uz: values.target.uz,
          ru: values.target.ru,
          en: values.target.en,
        },
        status: values.status
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
      >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
