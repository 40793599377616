import get from 'lodash/get';
import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useEssentials = () => {
  const initialData = { items: [] };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'select'],
    async () => {
      const { data } = await Api.ReferensEssential();

      const items = (get(data, 'data') || []).map(item => Mappers.ReferensEssential(item));

      return { items };
    },
    {
      initialData,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  return { ...data, ...args };
};

export default useEssentials;
