import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Textarea from "./Base/Textarea";

const Info = ({ title, name, required = true }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label
        title={t(title)}
        required={required}
      />
      <Textarea
        name={name}
        placeholder={t(`${title}_enter`)}
      />
    </>
  )
};

export default Info;