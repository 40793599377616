import './bootstrap';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import Auth from 'modules/auth/containers';
import i18n from "services/i18n";
import store from "store";
import App from './App';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Router>
            <Auth>
              <App />
            </Auth>
          </Router>
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);