import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import * as Uploader from "./Base/Uploader";

const Audio = ({ lang }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(`audio_${lang}`)} required />
      <Uploader.Audio lang={lang} />
    </>
  )
};

export default Audio;