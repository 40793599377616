import React from 'react';

import { EmptyListIcon, EmptyViewIcon } from 'assets/icons';

import classes from './Empty.module.scss';

const Empty = ({ title, description, isView }) => (
  <div className={classes.empty}>
    <div className={classes.title}>{title}</div>
    <div className={classes.description}>{description}</div>
    {isView ? <EmptyViewIcon /> : <EmptyListIcon />}
  </div>
);

export default Empty;
