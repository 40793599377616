import get from 'lodash/get';

export const HomeSlider = (item) => ({
  id: get(item, 'id'),
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  status: get(item, 'status') || false,
  photo: get(item, 'photo') || []
});