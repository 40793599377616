import React from 'react';

const EmptyListIcon = () => (
  <svg width="250" height="98" viewBox="0 0 250 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2843_36018)">
      <rect x="5" y="3" width="240" height="40" rx="12" fill="white" />
      <rect x="18" y="16" width="60" height="14" rx="4" fill="#E8EEF3" />
      <rect x="90" y="16" width="40" height="14" rx="4" fill="#E8EEF3" />
      <rect x="192" y="16" width="40" height="14" rx="4" fill="#E8EEF3" />
    </g>
    <g filter="url(#filter1_d_2843_36018)">
      <rect x="25" y="51" width="200" height="40" rx="12" fill="white" />
      <rect x="38" y="64" width="60" height="14" rx="4" fill="#E8EEF3" />
      <rect x="110" y="64" width="40" height="14" rx="4" fill="#E8EEF3" />
      <rect x="172" y="64" width="40" height="14" rx="4" fill="#E8EEF3" />
    </g>
    <defs>
      <filter
        id="filter0_d_2843_36018"
        x="0"
        y="0"
        width="250"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2843_36018" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2843_36018" result="shape" />
      </filter>
      <filter
        id="filter1_d_2843_36018"
        x="20"
        y="48"
        width="210"
        height="50"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2843_36018" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2843_36018" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default EmptyListIcon;
