import { http } from 'services';

export const ReferensShrine = ({ regionId }) => http.request.get(`referens/shrine/${regionId}`);

export const List = ({ params }) => http.request.get('shrine', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`shrine/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  const addTitle = {
    oz: values.addTitle.oz,
    uz: values.addTitle.uz,
    ru: values.addTitle.ru,
    en: values.addTitle.en
  }

  const addInfo = {
    oz: values.addInfo.oz,
    uz: values.addInfo.uz,
    ru: values.addInfo.ru,
    en: values.addInfo.en
  }

  const address = {
    oz: values.address.oz,
    uz: values.address.uz,
    ru: values.address.ru,
    en: values.address.en
  }

  const location = {
    x: values.location.x,
    y: values.location.y
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('audiooz', values.audio.oz);
  formData.append('audioru', values.audio.ru);
  formData.append('audioen', values.audio.en);

  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('add_title', JSON.stringify(addTitle));
  formData.append('add_info', JSON.stringify(addInfo));
  formData.append('address', JSON.stringify(address));
  formData.append('location', JSON.stringify(location));
  formData.append('region_id', values.regionId);
  formData.append('type', values.type);
  formData.append('top', values.top);
  formData.append('phone', values.phone);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('shrine', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  const addTitle = {
    oz: values.addTitle.oz,
    uz: values.addTitle.uz,
    ru: values.addTitle.ru,
    en: values.addTitle.en
  }

  const addInfo = {
    oz: values.addInfo.oz,
    uz: values.addInfo.uz,
    ru: values.addInfo.ru,
    en: values.addInfo.en
  }

  const address = {
    oz: values.address.oz,
    uz: values.address.uz,
    ru: values.address.ru,
    en: values.address.en
  }

  const location = {
    x: values.location.x,
    y: values.location.y
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('photo', values.photo[i]);
    }
  }

  formData.append('audiooz', values.audio.oz);
  formData.append('audioru', values.audio.ru);
  formData.append('audioen', values.audio.en);
  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('add_title', JSON.stringify(addTitle));
  formData.append('add_info', JSON.stringify(addInfo));
  formData.append('address', JSON.stringify(address));
  formData.append('location', JSON.stringify(location));
  formData.append('region_id', values.regionId);
  formData.append('type', values.type);
  formData.append('top', values.top);
  formData.append('phone', values.phone);
  formData.append('video', values.video);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('shrine', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('shrine', {
  data: {
    id
  }
});