import React from 'react';
import cx from "classnames";

import classes from './TopBar.module.scss';

const TopBar = ({ type, left, right }) => (
  <div className={cx(classes.wrapper, classes[`wrapper--${type}`])}>
    {left} {right}
  </div>
)

export default TopBar;