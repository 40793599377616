import get from 'lodash/get';

export const About = (item) => ({
  about: {
    oz: get(item, 'about.oz'),
    uz: get(item, 'about.uz'),
    ru: get(item, 'about.ru'),
    en: get(item, 'about.en'),
  },
  target: {
    oz: get(item, 'target.oz'),
    uz: get(item, 'target.uz'),
    ru: get(item, 'target.ru'),
    en: get(item, 'target.en'),
  },
  status: get(item, 'status') || false
});