import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Username = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('username')} />
      <Input
        name='username'
        placeholder={t('username_enter')}
      />
    </>
  )
};

export default Username;