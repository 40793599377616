import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { useTranslation } from "react-i18next";

import config from "config";
import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Create = ({ onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Create({ values });

      return Mappers.Clinic(data && data.data);
    },
    {
      onSuccess,
      onError,
      onSettled
    }
  );

  const validationSchema = yup.object().shape({
    name: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    title: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    info: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    address: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    location: yup.object().shape({
      x: yup.string().required(t('required')),
      y: yup.string().required(t('required'))
    }),
    regionId: yup.string().required(t('required')),
    phone: yup.string()
      .matches(config.pattern.phone, 'phone_number_is_not_valid').required(t('required')),
    photo: yup.string().nullable().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onError: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: {
          oz: '',
          uz: '',
          ru: '',
          en: '',
        },
        title: {
          oz: '',
          uz: '',
          ru: '',
          en: '',
        },
        info: {
          oz: '',
          uz: '',
          ru: '',
          en: '',
        },
        address: {
          oz: '',
          uz: '',
          ru: '',
          en: '',
        },
        location: {
          x: '',
          y: ''
        },
        regionId: '',
        shrineId: '',
        site: '',
        youtube: '',
        telegram: '',
        instagram: '',
        facebook: '',
        phone: '+998',
        status: '',
        photo: ''
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Create;
