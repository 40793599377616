import { http } from 'services';

export const List = ({ params }) => http.request.get('objects/theater', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`objects/theater/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  const address = {
    oz: values.address.oz,
    uz: values.address.uz,
    ru: values.address.ru,
    en: values.address.en
  }

  const link = {
    site: values.site,
    youtube: values.youtube,
    telegram: values.telegram,
    instagram: values.instagram,
    facebook: values.facebook
  }

  const location = {
    x: values.location.x,
    y: values.location.y
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('address', JSON.stringify(address));
  formData.append('location', JSON.stringify(location));
  formData.append('region_id', values.regionId);
  formData.append('shrine_id', values.shrineId);
  formData.append('link', JSON.stringify(link));
  formData.append('phone', values.phone);
  formData.append('work_time', values.workTime);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('objects/theater', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const title = {
    oz: values.title.oz,
    uz: values.title.uz,
    ru: values.title.ru,
    en: values.title.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  const address = {
    oz: values.address.oz,
    uz: values.address.uz,
    ru: values.address.ru,
    en: values.address.en
  }

  const link = {
    site: values.site,
    youtube: values.youtube,
    telegram: values.telegram,
    instagram: values.instagram,
    facebook: values.facebook
  }

  const location = {
    x: values.location.x,
    y: values.location.y
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('title', JSON.stringify(title));
  formData.append('info', JSON.stringify(info));
  formData.append('address', JSON.stringify(address));
  formData.append('location', JSON.stringify(location));
  formData.append('region_id', values.regionId);
  formData.append('shrine_id', values.shrineId);
  formData.append('link', JSON.stringify(link));
  formData.append('phone', values.phone);
  formData.append('work_time', values.workTime);
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('objects/theater', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('objects/theater', {
  data: {
    id
  }
});