import { useMutation, useQueryClient } from 'react-query';

import * as Api from '../api';
import * as Constants from '../constants';
import * as Mappers from '../mappers';

const useDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id }) => {
      const { data } = await Api.Delete({ id });

      return Mappers.Essential(data);
    },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: query => query.queryKey[0] === Constants.ENTITY && query.queryKey[1] === 'list'
          });
        }
      }
  );
};

export default useDelete;
