import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Link = ({ title, name }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(title)} />
      <Input
        name={name}
        placeholder={t(`${title}_enter`)}
      />
    </>
  )
};

export default Link;