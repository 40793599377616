import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('oz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['name', 'title', 'info', 'address']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Text
                title='name'
                name={`name[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Text
                title='title'
                name={`title[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Info
                title='info'
                name={`info[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Text
                title='address'
                name={`address[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Region />
            </Col>
            <Col sm={12}>
              <Fields.Link
                title='site_link'
                name='site'
              />
            </Col>
            <Col sm={12}>
              <Fields.Link
                title='youtube_link'
                name='youtube'
              />
            </Col>
            <Col sm={12}>
              <Fields.Link
                title='telegram_link'
                name='telegram'
              />
            </Col>
            <Col sm={12}>
              <Fields.Link
                title='instagram_link'
                name='instagram'
              />
            </Col>
            <Col sm={12}>
              <Fields.Link
                title='facebook_link'
                name='facebook'
              />
            </Col>
            <Col sm={12}>
              <Fields.Phone />
            </Col>
            <Col sm={12}>
              <Fields.Location axis='x' />
            </Col>
            <Col sm={12}>
              <Fields.Location axis='y' />
            </Col>
            <Col sm={12}>
              <Fields.Photo />
            </Col>
            <Col sm={12}>
              <Fields.Status />
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  )
};

export default Form;