import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Text = ({ title, name, required = true }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label
        title={t(title)}
        required={required}
      />
      <Input
        name={name}
        placeholder={t(`${title}_enter`)}
      />
    </>
  )
};

export default Text;