import React from 'react';
import { useTranslation } from "react-i18next";
import cx from "classnames";

import classes from './Badge.module.scss';

const Badge = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(
      classes.badge,
      status && classes['badge--active'],
    )}>
      {status ? t('active') : t('no_active')}
    </div>
  )
};

export default Badge;