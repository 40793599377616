import React from 'react';
import { useField } from 'formik';

import * as  Uploader from 'components/Uploader';

const Audio = ({ lang }) => {
  const [ , meta, helper] = useField({ name: `audio.${lang}` });

  const hasError = !!(meta.error && meta.touched);

  return (
    <Uploader.Audio
      lang={lang}
      message={hasError ? meta.error : ''}
      state={hasError ? 'error' : 'default'}
      onSelect={value => helper.setValue(value)}
    />
  );
};

export default Audio;
