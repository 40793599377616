import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Location = ({ axis }) => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t(`location_${axis}`)} required />
      <Input
        name={`location.${axis}`}
        placeholder={t(`location_${axis}_enter`)}
      />
    </>
  )
};

export default Location;