import React from 'react';
import { useTranslation } from "react-i18next";

import Label from "components/Label";
import Input from "./Base/Input";

const Email = () => {
  const { t } = useTranslation();

  return (
    <>
      <Label title={t('email')} required />
      <Input
        type='email'
        name='email'
        placeholder={t('email_enter')}
      />
    </>
  )
};

export default Email;