import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { DeleteIcon, EditIcon } from "assets/icons";

import * as Hooks from "modules/homeSlider/hooks";

import TopBar from "components/TopBar";
import Content from "components/Content";
import Table from "components/Table";
import Search from "components/Search";
import Button from "components/Button";
import Badge from "components/Badge";
import Empty from "components/Empty";
import Modal from "components/Modal";
import Spinner from "containers/Spinner";
import Delete from "./components/Delete";

const List = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selected, setSelected] = useState('');

  const search = searchParams.get('search') || '';

  const { items, isFetched } = Hooks.useList({ params: { search } });

  return (
    <>
      <TopBar
        type='white'
        left={<Search />}
        right={
          <Button
            onClick={() => navigate('/home-slider/create')}
            variant='secondary'
            title={t('add_btn')}
          />
        }
      />

      <Spinner isLoading={!isFetched} />

      <Content>
        { !items.length
          ? (
            <Empty
              title={t('list_empty_here')}
              description={t('list_will_appear_soon')}
            />
          ) : (
            <Table
              columns={[
                {
                  name: '№',
                  selector: row => row.id,
                  width: '80px'
                },
                {
                  name: t('title'),
                  selector: row => row.title[i18n.language],
                  maxWidth: '200px'
                },
                {
                  name: t('photo'),
                  selector: row => <img src={row.photo} alt={row.title[i18n.language]} />,
                },
                {
                  name: t('status'),
                  selector: row => <Badge status={row.status} />,
                },
                {
                  name: t('action'),
                  cell: row => (
                    <div style={{ display: "flex", columnGap: 15 }}>
                      <EditIcon onClick={() => navigate(`/home-slider/update/${row.id}`)} />
                      <DeleteIcon onClick={() => setSelected(row.id)} />
                    </div>
                  ),
                  ignoreRowClick: true,
                  allowOverflow: true,
                  button: true,
                },
              ]}
              data={items}
            />
          )
        }
      </Content>

      <Modal visible={!!selected} onCancel={() => setSelected('')}>
        <Delete id={selected} onCancel={() => setSelected('')} />
      </Modal>
    </>
  )
};

export default List;