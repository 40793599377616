import React from 'react';
import { NavLink } from "react-router-dom";

import Profile from "components/Profile";

import { menus } from "./menus";
import './Sidebar.scss';
import classes from './Sidebar.module.scss';

const Sidebar = () => (
  <div className={classes.wrapper}>
    <div className={classes.profile}>
      <Profile />
    </div>

    <ul className={classes.menu}>
      {menus.map(menu => (
        <li className={classes.item} key={menu.path}>
          <NavLink className={classes.link} to={menu.path}>
            {menu.name}
          </NavLink>
        </li>
      ))}
    </ul>
  </div>
);

export default Sidebar;