import React from 'react';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.Album(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        photo: '',
        status: values.status
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
