import React from 'react';
import { useTranslation } from "react-i18next";
import cx from 'classnames';

import classes from './Switch.module.scss';

const Switch = ({ checked, onChange }) => {
  const { t } = useTranslation();

  return (
    <div onClick={() => onChange && onChange(!checked)} className={classes.wrapper}>
    <div className={cx(classes.content, !checked && classes[`content--checked`])}>{t('not_active')}</div>
    <div className={cx(classes.content, checked && classes[`content--checked`])}>{t('active')}</div>
  </div>
  )
};

export default Switch;