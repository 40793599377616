import React from 'react';
import { useField } from 'formik';

import SwitchBase from 'components/Switch';

const Switch = ({ name, ...props }) => {
  const [field, , helper] = useField({ name });

  return (
    <SwitchBase
      {...props}
      checked={!!field.value}
      onChange={value => helper.setValue(value)}
    />
  );
};

export default Switch;