import get from 'lodash/get';

export const Shrine = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  addTitle: {
    oz: get(item, 'add_title.oz') || '',
    uz: get(item, 'add_title.uz') || '',
    ru: get(item, 'add_title.ru') || '',
    en: get(item, 'add_title.en') || ''
  },
  addInfo: {
    oz: get(item, 'add_info.oz') || '',
    uz: get(item, 'add_info.uz') || '',
    ru: get(item, 'add_info.ru') || '',
    en: get(item, 'add_info.en') || ''
  },
  address: {
    oz: get(item, 'address.oz') || '',
    uz: get(item, 'address.uz') || '',
    ru: get(item, 'address.ru') || '',
    en: get(item, 'address.en') || ''
  },
  type: get(item, 'type') || '',
  regionId: get(item, 'region_id') || '',
  top: get(item, 'top') || null,
  phone: get(item, 'phone') || '+998',
  location: get(item, 'location') || '',
  status: get(item, 'status') || false,
  video: get(item, 'video') || '',
  audio: {
    oz: get(item, 'audio.oz') || '',
    ru: get(item, 'audio.ru') || '',
    en: get(item, 'audio.en') || ''
  },
  photo: get(item, 'photo') || []
});

export const ReferensShrine = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  }
})

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});