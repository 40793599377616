import get from 'lodash/get';

export const Region = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  shortInfo: {
    oz: get(item, 'short_info.oz') || '',
    uz: get(item, 'short_info.uz') || '',
    ru: get(item, 'short_info.ru') || '',
    en: get(item, 'short_info.en') || ''
  },
  shrineCount: get(item, 'shrine_count') || null,
  status: get(item, 'status') || false,
  video: get(item, 'video') || '',
  photo: get(item, 'photo') || []
});

export const ReferensRegion = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  }
})
