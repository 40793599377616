import React from 'react';
import cx from 'classnames';

import classes from './LanguageTab.module.scss';

const LanguageTab = ({ active, onChange, languages }) => (
  <div className={classes.wrapper}>
    { languages.map(language => (
        <div
          onClick={() => onChange(language.value)}
          className={cx(classes.item, active === language.value && classes['item--active'])}
          key={language.value}
        >
          <img src={language.icon} alt={language.value} width={32} />
          {language.title}
          {!!language.badge && <div className={classes.badge}>{language.badge}</div>}
        </div>
      )
    )}
  </div>
)

export default LanguageTab;