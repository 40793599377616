import React from 'react';
import { useTranslation } from "react-i18next";
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';

import config from "config";
import { yup } from 'services';

import * as Api from '../api';
import * as Mappers from '../mappers';

const Update = ({ id, values, onSuccess, onError, onSettled, children }) => {
  const { t } = useTranslation();

  const mutation = useMutation(
    async values => {
      const { data } = await Api.Update({ id, values });

      return Mappers.Park(data && data.data);
    },
      {
        onSuccess,
        onError,
        onSettled
      }
  );

  const validationSchema = yup.object().shape({
    name: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    title: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    info: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    address: yup.object().shape({
      oz: yup.string().required(t('required')),
      uz: yup.string().required(t('required')),
      ru: yup.string().required(t('required')),
      en: yup.string().required(t('required'))
    }),
    location: yup.object().shape({
      x: yup.string().required(t('required')),
      y: yup.string().required(t('required'))
    }),
    regionId: yup.string().required(t('required')),
    phone: yup.string()
      .matches(config.pattern.phone, 'phone_number_is_not_valid').required(t('required')),
    workTime: yup.string().required(t('required'))
  });

  const handleSubmit = (values, { isSubmitting, setSubmitting }) => {
    if (!isSubmitting) {
      setSubmitting(true);
      mutation.mutate(values, {
        onSettled: () => setSubmitting(false)
      });
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        name: {
          oz: values.name.oz,
          uz: values.name.uz,
          ru: values.name.ru,
          en: values.name.en
        },
        title: {
          oz: values.title.oz,
          uz: values.title.uz,
          ru: values.title.ru,
          en: values.title.en
        },
        info: {
          oz: values.info.oz,
          uz: values.info.uz,
          ru: values.info.ru,
          en: values.info.en
        },
        address: {
          oz: values.address.oz,
          uz: values.address.uz,
          ru: values.address.ru,
          en: values.address.en
        },
        location: {
          x: values.location.x,
          y: values.location.y
        },
        regionId: values.regionId,
        shrineId: values.shrineId,
        phone: values.phone,
        site: values.site,
        youtube: values.youtube,
        telegram: values.telegram,
        instagram: values.instagram,
        facebook: values.facebook,
        workTime: values.workTime,
        status: values.status,
        photo: ''
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      {...{ validationSchema }}
    >
      {(props) => <Form>{children(props)}</Form>}
    </Formik>
  );
};

export default Update;
