import React  from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";

const Form = () => {
  return (
    <Content>
      <Row style={{ rowGap: 20 }}>
        <Col sm={8}>
          <Fields.Photo />
        </Col>
        <Col sm={4}>
          <Fields.Status />
        </Col>
      </Row>
    </Content>
  );
}

export default Form;