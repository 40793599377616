import get from 'lodash/get';

export const Essential = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  tag: get(item, 'tag') || '',
  status: get(item, 'status') || false
});

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});