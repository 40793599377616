import { http } from 'services';

export const List = ({ params }) => http.request.get('participant', {
  params: {
    search: params.search,
    page: params.page,
    limit: params.limit
  }
});

export const Single = ({ id }) => http.request.get(`participant/single/${id}`)

export const Create = ({ values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const role = {
    oz: values.role.oz,
    uz: values.role.uz,
    ru: values.role.ru,
    en: values.role.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('name', JSON.stringify(name));
  formData.append('role', JSON.stringify(role));
  formData.append('info', JSON.stringify(info));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.post('participant', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Update = ({ id, values }) => {
  let formData = new FormData();

  const name = {
    oz: values.name.oz,
    uz: values.name.uz,
    ru: values.name.ru,
    en: values.name.en
  }

  const role = {
    oz: values.role.oz,
    uz: values.role.uz,
    ru: values.role.ru,
    en: values.role.en
  }

  const info = {
    oz: values.info.oz,
    uz: values.info.uz,
    ru: values.info.ru,
    en: values.info.en
  }

  if (!!values.photo) {
    for(let i = 0; i < values.photo.length; i++) {
      formData.append('media', values.photo[i]);
    }
  }

  formData.append('id', id);
  formData.append('name', JSON.stringify(name));
  formData.append('role', JSON.stringify(role));
  formData.append('info', JSON.stringify(info));
  formData.append('status', JSON.stringify(!!values.status));

  return http.request.put('participant', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export const Delete = ({ id }) => http.request.delete('participant', {
  data: {
    id
  }
});