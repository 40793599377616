import React from 'react';
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Spacer from "components/Spacer";
import Spinner from "containers/Spinner";

import classes from './ConfirmDelete.module.scss';

const ConfirmDelete = ({ isLoading, onCancel, Confirm }) => {
  const { t } = useTranslation();

  return (
    <div  className={classes.wrapper}>
      <div className={classes.title}>{t('confirm_delete')}</div>
      <Spacer size={20} />
      <Spinner {...{ isLoading }} />
      <div className={classes.buttons}>
        <Button onClick={onCancel} variant="neutral" title={t('action_cancel')} block />
        <Button onClick={Confirm} variant="danger" title={t('action_delete')} type='submit' disabled={isLoading} block />
      </div>
    </div>
  );
};

export default ConfirmDelete;
