import React from 'react';
import { useTranslation } from "react-i18next";

import classes from './Profile.module.scss';

const Profile = () => {
  const { i18n } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <img src={require('assets/images/person.svg').default} alt="Person" width={60} height={60} />

      <select
        onChange={e => i18n.changeLanguage(e.target.value)}
        className={classes.select}
        value={i18n.language}
      >
        <option value="oz">O’zbekcha</option>
        <option value="uz">Узбекча</option>
        <option value="ru">Русский</option>
        <option value="en">English</option>
      </select>
    </div>
  )
};

export default Profile;