import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import * as Forms from 'modules/hotel/forms';

import TopBar from "components/TopBar";
import Button from "components/Button";
import Title from "components/Title";
import Spinner from 'containers/Spinner';
import Form from './components/Form';

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Forms.Create
      onSuccess={() => {
        toast.success('successfully_created');
        navigate('/hotel');
      }}
    >
      {({ values, isSubmitting }) => (
        <>
          <Spinner isLoading={isSubmitting} />

          <TopBar
            type='transparent'
            left={<Title text={t('hotel_create_title')} />}
            right={
              <div>
                <Button variant='white' title={t('action_cancel')} onClick={() => navigate(-1)} />
                <Button variant='success' title={t('action_save')} type='submit' />
              </div>
            }
          />

          <Form {...{ values }} />
        </>
      )}
    </Forms.Create>
  );
};

export default Create;