export const menus = [
  {
    path: '/region',
    name: 'Viloyat'
  },
  {
    path: '/shrine',
    name: 'Ziyoratgohlar'
  },
  {
    path: '/folk-game',
    name: 'Xalq o’yinlari'
  },
  {
    path: '/national-food',
    name: 'Milliy taomlar'
  },
  {
    path: '/leisure-park',
    name: 'Istirohat bog’lari'
  },
  {
    path: '/airport',
    name: 'Aeroport'
  },
  {
    path: '/train',
    name: 'Poyezd'
  },
  {
    path: '/theater-cinema',
    name: 'Teatr va kino'
  },
  {
    path: '/bank',
    name: 'Bank'
  },
  {
    path: '/market',
    name: 'Bozor'
  },
  {
    path: '/museum',
    name: 'Muzey'
  },
  {
    path: '/clinic',
    name: 'Klinika'
  },
  {
    path: '/hotel',
    name: 'Mehmonxonalar'
  },
  {
    path: '/restaurant',
    name: 'Restoranlar'
  },
  {
    path: '/mosque',
    name: 'Masjidlar'
  },
  {
    path: '/essential',
    name: 'Muhim malumot'
  },
  {
    path: '/sub-essential',
    name: 'Qo\'shimcha muhim malumot'
  },
  {
    path: '/home-slider',
    name: 'Home slider'
  },
  {
    path: '/album',
    name: 'Albomlar'
  },
  {
    path: '/about/update',
    name: 'Loyiha haqida'
  },
  {
    path: '/leader',
    name: 'Loyiha rahbari'
  },
  {
    path: '/participant',
    name: 'Loyiha a\'zolari'
  },
  {
    path: '/translation',
    name: 'Tarjimalar'
  }
];