import React, { useState } from 'react';
import { Row, Col } from 'react-grid-system';

import * as Fields from 'containers/Fields';

import Content from "components/Content";
import LanguageTab from "containers/LanguageTab";

const Form = () => {
  const [activeLanguage, setActiveLanguage] = useState('oz');

  return (
    <Row>
      <Col sm={12}>
        <LanguageTab
          active={activeLanguage}
          onChange={value => setActiveLanguage(value)}
          fields={['about', 'target']}
        />
      </Col>

      <Col sm={8}>
        <Content>
          <Row style={{ rowGap: 20 }}>
            <Col sm={12}>
              <Fields.Info
                title='about'
                name={`about[${activeLanguage}]`}
              />
            </Col>
            <Col sm={12}>
              <Fields.Info
                title='target'
                name={`target[${activeLanguage}]`}
              />
            </Col>
          </Row>
        </Content>
      </Col>

      <Col sm={4}>
        <Content>
          <Fields.Status />
        </Content>
      </Col>
    </Row>
  )
};

export default Form;