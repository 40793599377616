import get from 'lodash/get';
import { useQuery } from 'react-query';

import * as Api from '../api';
import * as Mappers from '../mappers';
import * as Constants from '../constants';

const useList = ({ params }) => {
  const initialData = { items: [] };

  const paramsWithDefaults = {
    search: params.search
  };

  const { data = initialData, ...args } = useQuery(
    [Constants.ENTITY, 'list', paramsWithDefaults],
      async () => {
        const { data } = await Api.List({
          params: paramsWithDefaults
        });

        const items = (get(data, 'data') || []).map(item => Mappers.Region(item));

        return { items };
      },
      { initialData, keepPreviousData: true }
  );

  return { ...data, ...args };
};

export default useList;
