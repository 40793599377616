import get from 'lodash/get';

export const Essential = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  title: {
    oz: get(item, 'title.oz') || '',
    uz: get(item, 'title.uz') || '',
    ru: get(item, 'title.ru') || '',
    en: get(item, 'title.en') || ''
  },
  photo: get(item, 'photo') || '',
  status: get(item, 'status') || false
});

export const ReferensEssential = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  }
});

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});