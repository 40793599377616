import React from 'react';
import { ToastContainer } from "react-toastify";

import classes from './Auth.module.scss';

const Auth = ({ children }) => (
  <div className={classes.wrapper}>
    <div className={classes.content}>
      {children}
    </div>

    <ToastContainer autoClose={1500} />
  </div>
);

export default Auth;