import React from 'react';
import { ToastContainer } from "react-toastify";

import Sidebar from "./Sidebar";

import classes from './Main.module.scss';

const Main = ({ children }) => (
  <div className={classes.wrapper}>
    <Sidebar />

    <div className={classes.content}>
      <div className={classes.container}>
        {children}
      </div>
    </div>

    <ToastContainer autoClose={1500} />
  </div>
);

export default Main;