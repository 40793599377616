import React from 'react';

import classes from './Label.module.scss';

const Label = ({ title, required }) => (
  <label className={classes.label}>
    {required && <span>*</span>}
    {title}
  </label>
);

export default Label;
