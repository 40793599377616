import get from 'lodash/get';

export const Album = (item) => ({
  id: get(item, 'id'),
  photo: get(item, 'photo') || '',
  status: get(item, 'status') || false
});

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});