import 'assets/styles/main.scss';

import store from "store";
import config from "config";
import { http } from 'services';

http.init({
  configFn: () => {
    const state = store.getState();
    const token = state.auth.token;

    return {
      baseURL: config.api.base_url,
      headers: {
        ...(token ? { token: token } : {})
      }
    };
  }
});