import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import * as Forms from 'modules/homeSlider/forms';
import * as Hooks from 'modules/homeSlider/hooks';

import TopBar from "components/TopBar";
import Title from "components/Title";
import Button from "components/Button";
import Spinner from 'containers/Spinner';
import Form from './components/Form';

const Update = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { item, isFetched } = Hooks.useSingle({ id });

  return (
    <Forms.Update
      {...{ id }}
      values={item}
      onSuccess={() => {
        toast.success('successfully_updated');
        navigate('/home-slider');
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Spinner isLoading={!isFetched || isSubmitting} />

          <TopBar
            type='transparent'
            left={<Title text={t('homeslider_create_title')} />}
            right={
              <div>
                <Button variant='white' title={t('action_cancel')} onClick={() => navigate(-1)} />
                <Button variant='success' title={t('action_save')} type='submit' />
              </div>
            }
          />

          <Form/>
        </>
      )}
    </Forms.Update>
  );
};

export default Update;
