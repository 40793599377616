import get from 'lodash/get';

export const Participant = (item) => ({
  id: get(item, 'id'),
  name: {
    oz: get(item, 'name.oz') || '',
    uz: get(item, 'name.uz') || '',
    ru: get(item, 'name.ru') || '',
    en: get(item, 'name.en') || ''
  },
  role: {
    oz: get(item, 'role.oz') || '',
    uz: get(item, 'role.uz') || '',
    ru: get(item, 'role.ru') || '',
    en: get(item, 'role.en') || ''
  },
  info: {
    oz: get(item, 'info.oz') || '',
    uz: get(item, 'info.uz') || '',
    ru: get(item, 'info.ru') || '',
    en: get(item, 'info.en') || ''
  },
  status: get(item, 'status') || false,
  photo: get(item, 'photo') || []
})

export const Meta = (item) => ({
  totalPages: get(item, 'totalPages') || 1,
  totalItems: get(item, 'totalItems') || 0
});